import React from "react";
import { ParkingBayList } from "./ParkingBay/ParkingBayList";

export const ParkingBay = () => {
  return (
    <React.Fragment>
      <ParkingBayList />
    </React.Fragment>
  );
};
